.navbar-custom {
  position: relative;
  margin-top: 110px;
  width: 100%;
  background-color: white;
  color: rgb(165,42,42);
  text-align: center;
  height: 60px;
  z-index: 1000000;
  /* z-index: 20000000000000; */
  /* box-shadow:          0px 7px 3px -3px black; */
}

.card-sub-menu {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
  /* box-shadow: darkred 0px 25px 20px -20px;  */
  margin: 0 auto;
  width: 700px;
  /* width: 700px; */
  transition: 0.3s;
  min-height: 200px;
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 30px;
  padding-top: 20px;
  padding-bottom: 24px;
  position: relative;
  z-index: 1000000000;
  border-bottom: 1px solid darkred;
}


.card-main-menu {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
  /* box-shadow: darkred 0px 25px 20px -20px;  */
  margin: 0 auto;
  width: 570px;
  /* width: 700px; */
  transition: 0.3s;
  min-height: 20px;
  padding-left: 70px;
  padding-right: 70px;
  margin-bottom: 8px;
  padding-top: 30px;
  padding-bottom: 13px;
  position: relative;
  z-index: 1000000000;
  /* border: 1px solid darkred; */
  border-bottom: 1px solid darkred;
}

.main-menu-spacer {
  height: 16px;
}

/* On mouse-over, add a deeper shadow */
.card-main-menu:hover {
  /* border-top: 0px solid darkred;
  border-bottom: 0px solid darkred; */
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0.2);
  cursor: pointer;

}

/* Add some padding inside the card container */
.container-sub-menu {
  padding: 2px 16px;
  width: 100%
}

.container-main-menu {
  /* padding: 2px 16px; */
  width: 100%
}

.header-row {
  margin-top: 22px;
  margin-bottom: -2px;
}

.header-buffer {
  width: 4px;
}

.table-buffer {
  width: 4px;
  border-bottom: 0px solid white;
}

#mySidebar {
  display: none;
}

.table-divide {
  color: white;
  height: 14px;
  font-family: 'Caveat', cursive !important;
  border-top: 1px solid brown;
  width: 180px;
  margin: 0 auto;
  margin-bottom: 7px;
}

.main-table-divide {
  color: white;
  height: 12px;
  font-family: 'Caveat', cursive !important;
  border-top: 1px solid brown;
  width: 50px;
  margin: 0 auto;
  margin-top: 14px;
  /* margin-bottom: -20px; */
}

.sub-menu-price-desc {
  text-align: center !important;
  /* word-wrap: break-word;  */
  font-family: 'Caveat', cursive !important;
  font-size: 22px;
  margin: 0 auto !important;
  /* text-indent: -300px; */
}

.td-price-desc {
  margin: 0 auto !important;
  padding-left: 40px !important;
  padding-right: 60px !important;
  text-align: center !important;
  /* word-wrap: break-word;  */
  font-family: 'Caveat', cursive !important;
  font-size: 22px;
  margin: 0 auto !important;
  /* text-indent: -300px; */
}

.table-header {
  font-size: 14px;
}

.table-header-item {
  font-size: 14px;
  text-align: center;
  width: 130px;
  color: white !important;
}

.table-header-desc {
  font-size: 14px;
  text-align: left;
  padding-left: 60px;
}

.table-header-price {
  font-size: 14px;
  text-align: left;
  padding-left: 5px;
  width: 110px;
}

tr {
  width: 400px;
  cursor: pointer;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.main-food-menu {
  position: absolute;
  transform: translate(-50%, 0);
  left: 50%;
  /* transform: translate(-50%, -50%); */
 margin: 0 auto;
 /* overflow-x: hidden; */
  height: 490px;
  }

  .classname::-webkit-scrollbar {
    display: none; /* for Chrome, Safari and Opera */
}

.classname{
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.main-selection-menu {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1000px;
 margin: auto;
 /* overflow-y: hidden; */
 height: 470px;
}

.reserve-tablet {
  display: none;
}

.carousel-control-next {
  color: brown;
}

.mobile-modal {
  display: none;
}

.carousel-caption-meat-lovers-mobile {
  display: none;
}


.curtain {
  z-index: -20;
  position: absolute;
  left: 0;
  width: 100%;
}

.reserve-slider {
  top: 0;
  width: 100%;
  left: 15px;
  margin-left: 3%;
}

.reserve-fee {
  color: white;
}

p{
  color: white;
}

.form-container {
  text-align: center;
  margin: 0 auto;
  display: inline-block;
}

.reserve-form {
  color: white;
  margin: auto 0;
  text-align: center;
  margin-right: 0;
}

#reserve-button {
  width: 40%;
  background-color: white;
}

ul {
  color: white;
  text-align: left;
}

.original-class {
  width: 20%;
}

.thumbnail-class {
  width: 20%;
}


.top-border-red {
    position: fixed;
    width: 100%;
    left: 0;
    height: 10px;
    top: 0;
  }

  .reserve-pics {
    width: 40%;
  }

.table-div {
  display: block;
  height: 300px;
  overflow: auto;

}

.table-div-2 {
  /* display: block; */
  height: 300px;
  overflow-y: auto;
}

h2 {
  font-family: 'BrandonGrotesqueWeb-Black';
  color: brown;
  text-shadow: 0px 0px 0px black;
}

.col-xs-6 {
  color: white;
}

h5 {
  /*font-family: 'Suranna', serif;*/
  font-family: 'BrandonGrotesqueWeb-Black';
  font-style: normal;
  font-size: 60px;
  color: darkred;
  /* text-shadow: 2px 2px 8px black; */
  margin-bottom: -44px;
  margin-top: 6px;
  font-size: 63px;
  display: inline-block;
  text-shadow: 3px 4px 7px rgba(81,67,21,0.8);
  text-shadow: 0px 8px 10px rgba(81,67,21,0.8);
  text-shadow: 0px 8px 10px lightslategrey;

  letter-spacing: 14px;
  /* text-shadow: 3px 8px 10px darkgrey; */

  /* text-shadow: 2px 4px 4px rgba(0,0,0,0.2),
                 0px -5px 10px rgba(255,255,255,0.15); */
}

h7 {
  margin-top: 0px;
  margin-bottom: 0px;
  color: black;
  color: white;
  text-shadow: .5px .5px 2px black;
}


h10 {
  font-family: 'BrandonGrotesqueWeb-Black';
  color: brown;
  margin-bottom: -4px;
}

.nav-wide{
  display: inline-block;
  float: none;
  vertical-align: top;
  text-align: center;
  margin-top: -100px;
}

li {
  padding-left: 35px;
  font-family: 'BrandonGrotesqueWeb-Black';
  font-size: 15px;
  color: darkred;
  letter-spacing: 1.5px;
}

li:hover {
  font-weight: strong;
  text-shadow: .25px .25px 1px black;
  cursor: pointer;
  color: brown;

}

a:hover {
  font-weight: strong;
  text-shadow: .25px .25px 1px black;
  cursor: pointer;
  color: brown;
  text-decoration: none;
}

li.reserve-bullets {
  color: brown;
  list-style-type: square;
  font-size: 16px;
  /* text-shadow: 1px 1px 2px black; */

}

.balloon {
  width: 63%;
  position: absolute;
  z-index: -100;
  bottom: 0;
  left: 0;
  margin-left: -120px;
  margin-bottom: -95px;
}

.reserve-div {
  margin-top: -50px;
}

.test-class {
  height: 440px;
  width: 70%;
  margin: auto;
  opacity: 0.7;
  position: relative;
  z-index: initial;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow-y: auto;
  border: 1px solid brown;
  box-sizing: border-box;
}

.home-class {
  height: 520px !important;
  /* height: 400px; */
  width: 65%;
  max-width: 100px;
  margin: auto;
  margin-top: -10px;
  opacity: 0.95;
  position: relative;
  z-index: 100;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  overflow-y: auto;
  /* border: 1px solid brown; */
  border-left: 10px solid brown;
  box-sizing: border-box;
  overflow-y: hidden;
  overflow: hidden;
  max-width: 1100px;
}

.test-classII {
  height: 580px;
  width: 400px;
  margin: auto;
  opacity: .9;
  position: relative;
  z-index: initial;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  border: 1px solid brown;
  box-sizing: border-box;
}

.test-classIII {
  height: 480px;
  width: 70%;
  margin: auto;
  opacity: 0.9;
  position: relative;
  z-index: initial;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow-y: auto;
  border: 1px solid brown;
  box-sizing: border-box;
}

.test-class-register {
  height: 480px;
  width: 70%;
  margin: auto;
  opacity: 0.9;
  position: relative;
  z-index: initial;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow-y: auto;
  border: 1px solid brown;
  box-sizing: border-box;
}

.test-class-welcome {
  height: 480px;
  width: 80%;
  margin: auto;
  opacity: 0.9;
  position: relative;
  z-index: initial;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow-y: auto;
  border: 1px solid brown;
  box-sizing: border-box;
  background-color: white;
}

.test-class-edit-account {
  height: 480px;
  width: 80%;
  margin: auto;
  opacity: 0.9;
  position: relative;
  z-index: initial;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow-y: auto;
  border: 1px solid brown;
  box-sizing: border-box;
}

h6 {
  font-family: 'Princess Sofia', cursive;
  font-size: 30px;
  color: black;
}

.menu-categories {
  font-family: 'Princess Sofia', cursive;
  font-size: 40px;
  color: rgb(165,42,42);
  cursor: pointer;
}

.X {
  text-align: right;
  cursor: pointer;
}

h8 {
  font-family: 'BrandonGrotesqueWeb-Black';
  color: black;
  font-size: 20px;
  color: darkred; 
  font-weight: 500;
  /* text-shadow: 0.5px 0.5px 6px black;  */
  text-shadow: 2px 4px 4px rgba(0,0,0,0.2),
                 0px -5px 10px rgba(255,255,255,0.15);
                 text-shadow: 0px 11px 10px rgba(81,67,21,0.8);
                 text-shadow: 3px 4px 5px grey;
                 text-shadow: 0px 5px 10px rgba(81,67,21,0.8);
                 text-shadow: 0px 8px 10px rgba(81,67,21,0.8);
                 text-shadow: 0px 8px 10px lightgrey;
                 text-shadow: 0px 8px 10px lightslategrey;




}

.card-text {
  font-family: 'BrandonGrotesqueWeb-Black';
  color: brown;
  font-size: 20px;
}

.card, .cardII {
  width: 30%;
  box-shadow: 0 16px 32px 0 black;
  margin-left: 10%;
  margin: 0 auto;
  display: inline-block;
}

.card:hover, .cardII:hover {
  box-shadow: 0 0px 0px 0 rgba(0,0,0,0.2);
  cursor: pointer;
  border: 1px solid brown;
  box-sizing: border-box;
}
.card-img-top {
  width: 100%;
}

.cardII {
  float: left;
  position: relative;
}

.or {
  margin-top: 60px;
}

.container {
  border-bottom: 1px solid brown;
  /* position: absolute; */
  /* margin-top: -50px; */
  padding-bottom: 16px;
  top: 0;
  margin-bottom: 150px;
  margin: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-left: auto;
  margin-right: auto;
}

.upper-title {
  margin: auto;
}



/* Full-width inputs */
input[type=text], input[type=password], input[type=phone], input[type=email] {
  width: 350px;
  /* width: 90%; */
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid brown;
  box-sizing: border-box;
}

/* Set a style for all buttons */
/* button {
  background-color: #4CAF50;
  color: brown;
  padding: 14px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 90%;
  background-color: #54FF9F;
  box-shadow: 0 16px 32px 0 black;
} */

.reserve-room-title {
  color: white;
  font-size: 40px;
  text-align: center;
  text-shadow: 2px 2px 8px black;
}

.pompeii-title {
  font-size: 28px;
  text-align: center;
  letter-spacing: 2px;
}

/* Add a hover effect for buttons */
/* button:hover {
  opacity: 0.9;
  background-color: #54FF9F;
  box-shadow: 0 0px 0px 0 black;
} */

/* Extra style for the cancel button (red) */
.cancelbtn {
  width: auto;
  padding: 10px 18px;
  background-color: brown;
}

/* Center the avatar image inside this container */
.imgcontainer {
  text-align: center;
  margin: 24px 0 12px 0;
}

/* Avatar image */
img.avatar {
  width: 30%;
  border-radius: 50%;
}

/* Add padding to containers */
.login {
  padding: 16px;
}

/* The "Forgot password" text */
span.psw {
  float: right;
  padding-top: 16px;
}

label, .label {
  font-family: 'BrandonGrotesqueWeb-Black';
  color: brown;
  font-size: 16px;
  text-align: left
}

.name-information, .address-information {
  width: 280px;
  text-align: center;
  margin: 0 auto;
}


.registration-form-container, .address-information-container {
  text-align: center;
  margin: 0 auto;
}

.custom-select {
  position: relative;
  font-family: Arial;
  width: 280px;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid brown;
  box-sizing: border-box;
  border-radius: 0;
  height: 50px;
}

.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  border-radius: 0.3rem;
  background-color: brown;
  color: #fff;
  text-align: center;
}


.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid brown;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: bisque;
  border-color: brown;
  color: black;
  border-radius: 0px;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px hsl(208, 99%, 50%);
  border-color: hsl(208, 99%, 50%);
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 5px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: white;
}

.menu-header {
  /* margin-top: 40px;  */
  /* padding-top: 20px; */
  font-weight: normal;
  color: white !important;
  letter-spacing: 5px !important;
  font-family: 'BrandonGrotesqueWeb-Black' !important;
  font-family: 'Caveat', cursive !important;
  font-weight: bolder;
  background-color: darkred;
  width: 700px;
  margin: 0 auto;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
}

.main-menu-header {
  /* margin-top: 40px;  */
  /* padding-top: 20px; */
  font-weight: normal;
  color: white !important;
  letter-spacing: 5px !important;
  font-family: 'BrandonGrotesqueWeb-Black' !important;
  font-family: 'Caveat', cursive !important;
  font-weight: bolder;
  background-color: darkred;
  width: 570px;
  margin: 0 auto;
  position: absolute;
  z-index: 10000000000000;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.login-name-div {
  display: inline-block;
  position: relative;
  z-index: -1;
  margin-left: -200px;
  margin-bottom: -100px;
}


table, td, th {  
  border-top: 0px solid brown;
  border-bottom: 0px solid brown;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 12px;
  font-weight: normal;
  overflow-x: hidden;
  /* word-wrap: break-word; */
}

table {
  border-collapse: collapse;
  width: 500px;
  margin: 0 auto;
  box-shadow: 0 16px 32px 0 brown;
  /* font-weight: bold; */
  text-align: center;
  font-family: 'BrandonGrotesqueWeb-Black';
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 60px;
  max-height: 200px;
  padding-left: 30px;
  padding-right: 30px;
  /* position: absolute; */
  /* overflow-y: scroll; */
}

th, td {
  padding-top: 10px;
  cursor: pointer;
  padding-bottom: 8px;
  /* border-bottom: 1px solid brown; */
  overflow-y: hidden;
}

.home-click {
  color: darkred;
  letter-spacing: 2px;
  font-size: 12px;
  font-family: 'BrandonGrotesqueWeb-Black';
  cursor: pointer;
}

.td-price-price {
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
  font-size: 30px;
  margin: 0 auto;
  color: darkred;
  /* font-weight: bold; */
  text-align: center;
  font-family: 'BrandonGrotesqueWeb-Black';
  cursor: pointer;
  font-size: 16px;
  margin-bottom: 60px;
  padding-bottom: 180px !important;
  max-height: 200px;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 2px;
  /* position: absolute; */
  /* overflow-y: scroll; */
}

.menu-choice-table {
  /* width: 700px; */
  z-index: 3000000000;
  position: relative;
  height: 470px;
}

td:hover {
  background-color: brown;
  color: white;
}

th {
  background-color: brown;
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
  cursor: pointer;

}

td {
  background-color: white;

}

.add-address-button {
  border: 1px solid brown;
  box-sizing: border-box;
  border-radius: 0px;
}

.delete-img {
  width: 25px;
  color: brown;
}

.editEntry, .delete-img {
  cursor: pointer;
}

#confirmDeliveryInformation {
  text-align: left;
  width: 300px;
  border: 1px solid brown;
  box-sizing: border-box;
}

.card-header {
  background-color: brown;
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
}

#forgotPassword {
  cursor: pointer;
}

.meat-lovers-home-full {
  /* width: 1060px; */
  min-height: 110%;
  /* max-height: 100%; */
  max-width: 110%;
  object-position: center;
  position: relative;
  margin: 0 auto;
  /* top: 50%; */
  left: 48%; 
  transform: translate(-50%, -0%);
}

.meat-lovers-home-full-party {
  width: 1260px;
  /* min-height: 110%; */
  max-height: 100%;
  max-width: 110%;
  object-position: center;
  position: relative;
  margin: 0 auto;
  /* top: 50%; */
  left: 48%; 
  transform: translate(-50%, -0%);
}

h3{
  text-shadow: 2px 2px 8px black;
  font-family: 'BrandonGrotesqueWeb-Black';
  font-size: 80px;
}

.carousel-caption {
 position: relative;
 text-align: center;
}


.carousel-caption-meat-lovers {
  position: relative;
  text-align: center;
  left: 0; 
  top: 40%;
  /* transform: translate(-50%, -50%); */
  /* margin-bottom: 200%; */
 }

.meat-lovers-caption {
  position: absolute;
  bottom: 650% !important;
  /* left: 0%; */
  margin: 0 auto;
  margin-top: 100px;
  padding-top: 50px;
  /* transform: translate(-50%, -50%); */
  font-size: 60px;
  text-shadow: 4px 4px 16px black;
  color: white;
  font-family: 'Tinos', serif;
  /* box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
  padding-left: 40px;
  padding-right: 10px;

  /* vertical-align: middle; */
  font-family: 'BrandonGrotesqueWeb-Black';

}

.menu-caption {
  text-align: right;
  position: absolute;
  bottom: 220%;
  right: 20%;
  /* transform: translate(-50%, -50%); */
  font-size: 50px;
  text-shadow: 2px 2px 8px black;
  color: white;
  font-family: 'Tinos', serif;

}

.menu {
  font-size: 30px;
}

.dining-caption {
  text-align: center;
  position: absolute;
  bottom: 220%;
  left: 2%;
  /* transform: translate(-50%, -50%); */
  font-size: 50px;
  text-shadow: 2px 2px 8px black;
  background-color: brown;
  opacity: 0.7;
  color: white;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 60px;
  border: 1px solid white;
  /* padding-bottom: 600px;  */
  /* position: relative;
  z-index: 3; */ 
}

.party-room-caption {
  text-align: center;
  position: absolute;
  bottom: 300%;
  /* transform: translate(-50%, -50%); */
  font-size: 50px;
  text-shadow: 2px 2px 8px black;
  color: white;
  padding-top: 15px;
  border: 1px solid white;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  background-color: white;
  opacity: 0.9;

  /* padding-bottom: 600px;  */
  /* position: relative; */
  z-index: 30000; 
}

.for-over {
  text-shadow: 1px 1px 4px black;
  font-size: 15px;
  font-family: 'OrkneyRegular';
  font-weight: bold;
  margin-bottom: -100px;
  margin-top: -125px;
}

.credit-cards{
  width: 10px;
  left: 40%;
  float: left;
  left: 8%;
  margin-right: -180px;
  margin-bottom: 50px;
  bottom: 0px;
  position: relative;
  z-index: 100000;
}

.credit-card-div {
  margin-top: -24px;
  width: 78%;
  /* margin-bottom: 100px; */
}

.harvard-header {
  text-shadow: inset 1px 1px 4px brown;
  font-size: 50px;
  font-family: 'OrkneyRegular';
  font-family: 'BrandonGrotesqueWeb-Black';
  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
  text-align: left;
  color: white;
  /* margin-bottom: -120px; */
  /* margin-top: -97px; */
  font-weight: bold;
  line-height: 0.8;
  text-shadow: inset 1px 1px 4px brown !important;
  letter-spacing: -2.7px;
  margin-bottom: 0px;
  margin-top: -200px !important;
}

.grapes {
  position: fixed;
  width: 200px;
  height: 200px;
  z-index: -100;
  left: 0;
  top: 0;
  margin: auto 0;
  display: inline-block;
  text-align: center;
  opacity: 0.5;
}

.grapes-left {
  position: fixed;
  width: 260px;
  height: 400px;
  z-index: -10000000000000;
  right: 0;
  top: 0;
  display: inline-block;
  text-align: center;
  opacity: 0.6;
}

.years {
  font-family: 'Tinos', serif;
  font-size: 45px;
  margin-bottom: -90px;
  text-shadow: 1px 1px 4px black;
}

.red-bottom-border {
  width: 50%;
  right: 0;
}

.favorite {
  font-size: 80px;
  text-shadow: 1px 1px 4px black;
  color: white;
  font-family: 'Forum', cursive;
  /* font-weight: bold; */
  font-family: 'Tinos', serif;
  font-family: 'Princess Sofia', cursive;
}

.favorite-harvard {
  text-shadow: 0px 0px 0px black;
  color: brown;
}

.dining {
  font-size: 75px;
  text-shadow: 2px 2px 8px black;
  color: white;
  font-family: 'Forum', cursive;
  /* font-weight: bold; */
  font-family: 'Tinos', serif;
  font-family: 'Princess Sofia', cursive;

  opacity: 1;

}

#row {
  width: 100%;
  margin: auto 0;
}

.reserve-caption {
  width: 80%;
  font-size: 60px;
  text-shadow: 1px 1px 4px black;
  color: brown;
  font-family: 'Forum', cursive;
  /* font-weight: bold; */
  font-family: 'Tinos', serif;
  font-family: 'Princess Sofia', cursive;
  opacity: 1;
}

.menu {
  font-size: 60px;
  text-shadow: 2px 2px 8px black;
  color: white;
  font-family: 'Forum', cursive;
  font-family: 'Tinos', serif;
  font-family: 'Princess Sofia', cursive;
  font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
  font-weight: normal;
  line-height: 0.8;
  text-shadow: inset 1px 1px 4px brown !important;
  letter-spacing: -2.9px;
}

.authentic {
  font-family: 'Princess Sofia', cursive;
  font-family: 'Caveat', cursive !important;
}

.btn.btn-outline-danger {
  border-radius: 0;
  margin-top: -140px;
  width: 350px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: darkred;
  opacity: 0.85;
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* border: 4px solid brown; */
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important; 
}



.btn.btn-outline-danger:hover {
  /* margin-left: -180px; */
  width: 350px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: darkred;
  opacity: 0.85;
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* border: 4px solid brown; */
  box-shadow: rgba(0, 0, 0, 0.56) 0px 0px 0px 0px !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;

}

.btn.btn-outline-danger-2 {
  border-radius: 0;
  margin-top: -150px !important;
  width: 350px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: darkred;
  opacity: 0.85;
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* border: 4px solid brown; */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */

  /* box-shadow: black 0px 22px 70px 4px !important; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important; 
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px !important;
  margin-left: -80px;
  top: 50% !important;
}

.btn-outline-danger-2:hover {
  border-radius: 0;
  width: 350px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: darkred;
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
  box-shadow: black 0px 0px 0px 0px !important;

}

p {
  font-size: 24px;
  font-family: 'BrandonGrotesqueWeb-Black';
  font-family: 'Caveat', cursive !important;
}

.sidenav-text {
  font-size: 40px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 25px;
  /* text-shadow: 2px 2px 8px black */
}

.sidenav-text-menu {
  font-size: 58px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  margin-top: 10px;
  padding-top: 6px;
  padding-bottom: -10px;
  text-shadow: 2px -2px 8px brown;
  z-index: 1000000;
  /* text-shadow: 2px -2px 8px brown; */
  /* text-shadow: 2px 2px 8px black */
  /* text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black; */
}


.actual-menu-options {
  padding-top: 12%;
}

.sidenav-text-menu:hover {
  font-size: 16px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: black;
  cursor: pointer;
  text-align: center;
  margin-top: 15px;
  padding-top: 5px;
  text-shadow: 2px -2px 8px brown;
  /* text-shadow: 2px 2px 8px black */
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;

}

.sidenav-text-menu.click {
  font-size: 16px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
  margin-top: -60px;

}

.sidenav-text-menu.click:hover {
  font-size: 16px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 0px;
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
  margin-top: -15px;
  padding-top: 5px;
}

.sidenav-text-menu:hover {
  font-size: 60px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 0px;
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
  /* text-shadow: 2px 2px 8px black */
}


.sidenav-text-menu {
  font-size: 60px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 0px;
  /* color: transparent; */
  /* text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black; */
  /* text-shadow: 2px 2px 8px black */
}


.sidenav-text-contact {
  font-size: 62px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  margin-top: -10px;
  text-shadow: 4px -4px 16px brown;
  /* text-shadow: 2px 2px 8px black */
}

.sidenav-text-contact:hover {
  font-size: 62px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 0px;
  text-decoration: none;
  text-shadow: 4px 4px 16px black;
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
  /* text-shadow: 2px 2px 8px black */
}

.sidenav-text-account {
  font-size: 42px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  margin-bottom: 30px;
  text-decoration: none;
  text-shadow: 4px 4px 16px brown;
  line-height: 42px;
  /* text-shadow: 2px 2px 8px black */
}


.sidenav-text-account:hover {
  font-size: 42px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 0px;
  text-decoration: none;
  text-shadow: 4px 4px 16px black;
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
  /* text-shadow: 2px 2px 8px black */
}

.sidenav-text-order {
  font-size: 82px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 25px;
  text-shadow: 2px 2px 8px brown
  /* text-shadow: 2px 2px 8px black */
}

.sidenav-text-order:hover {
  font-size: 82px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 0px;
  text-shadow: 4px 4px 16px black;
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
  /* text-shadow: 4px 4px 16px black */
  /* text-shadow: 2px 2px 8px black */
}


.sidenav-text-login {
  font-size: 30px;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* font-family: 'Permanent Marker', cursive; */
  color: white;
  cursor: pointer;
  text-align: center;
  padding-top: 25px;
  text-shadow: 4px 4px 16px brown;
}


.sidenav-text-login:hover {
  color: transparent;
  text-shadow: 0 0 20px rgba(198,28,39,0.8), 0 0 0 black;
}

.stars {
  width: 20%;
}

.hamburger-menu {
  width: 6%;
  position: fixed;
  margin-right: 20px;
  cursor: pointer;
  margin-top: 40px;
  display: none;
  margin-left: -190px;
}

.biography {
  color: white;
  height: 200px;
}

.container-div {
  text-align: center;
  width: 100%;
  display: block;
  padding-top: -100px;
  /* left: 50%;
  transform: translate(-50%, -50%); */
}

/* The sidebar menu */
.sidebar {
  height: 0%; /* 100% Full-height */
  /* width: 100%; 0 width - change this with JavaScript */
  left: 0%;
  position: fixed;
  z-index: 100000; /* Stay on top */
  /* top: 0; */
  bottom: 0;
  background-color: darkred; /* Black*/
  text-align: center;
  /* padding-top: 60px; */
   /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  opacity: 0.95;
display: none;
margin: 0 auto;
  /* border-right: 1px solid black; */
  /* box-shadow:    -3px 0 5px 5px black; */
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden;
	box-shadow: 0 -5px 10px -5px black;

}

.party-room-sidebar {
  height: 0px; /* 100% Full-height */
  width: 90%; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 30000000000000 !important; /* Stay on top */
  top: 0;
  left: 5%;
  background-color: brown; /* Black*/
  padding-top: 0px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden;
  /* padding-bottom: 50px; */
}

.reserve-party-button {
  background-color: white;
  color: brown;
  font-weight: bold;
  padding: 15px;
  padding-left: 35px;
  padding-right: 35px;
}

/* The sidebar links */
/* .sidebar a {
  padding: 8px 8px 8px 10px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s; */
/* } */

/* When you mouse over the navigation links, change their color */
.sidenav-text {
  color: #f1f1f1;
  transition: 0.5s;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
}

.party-room-sidebar .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
  z-index: 20000000000000 !important;
}


.meat-lovers-home-vertical {
  display: none;
  /* height: 100%;
  width: 150%; */
}

.meat-lovers-home-full {
  display: block;
}

#reserveTitle {
  font-family: 'Princess Sofia', cursive;
  font-size: 30px;
}



/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
  transition: margin-left .5s; /* If you want a transition effect */
  padding: 20px;
}

.sidenav-title {
  font-size: 35px;
  color: white;
  display: block;
  overflow-x: hidden;
  transition: 0.3s;
  overflow-y: hidden;
  margin-top: 10px;
  margin-bottom: 3px;
  text-shadow: 0px 0px 0px lightslategrey;

}
.sidebar {
  overflow-x: hidden;
  display: block;
  height: 0%;
}

a{
  color: brown;
}

.sidenav-line {
  color: white;
  font-size: 20px;
}

.sidenav-pizza {
  font-size: 28px;
  color: white;
  font-family: 'Princess Sofia', cursive;
  margin: 0 auto;
  margin-left: 7px;
}

.sidenav-menu-options {
  display: block;
  position: absolute;
}

.side-menu {
  padding-top: 0px;
  padding-bottom: 8px;
  /* border-bottom: 1px solid white; */
  text-align: center;
  display: inline-block;
  -webkit-text-stroke: 1px brown;
}

.side-menu-contact {
  padding-top: 10px;
  padding-bottom: 10px;
  /* border-bottom: 1px solid white; */
  text-align: center;
  display: inline-block;
  /* border-bottom: 1px solid white; */
  /* box-shadow: inset 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23); */
}

.side-menu:hover {
  /* background-color: white;
  color: brown; */
  cursor: pointer;
  text-shadow: 4px 4px 16px brown;
  text-decoration: none;
}


.meat-lovers-container {
  position: relative;
  display: block;
  margin-left: 0 auto;
  margin-right: 0 auto;
  overflow: hidden;
  overflow-x: hidden;
  width: 1200px;
  max-height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
}

  .page-sub-header {
    margin-top: -36px;
    margin-bottom: -50px;
    padding-top: 0px;
    }
  
  .sub-menu-desc {
    text-align: center !important;
    padding-top: 20px;
    margin: 0 auto;
    /* font-weight: bold; */
    text-align: center;
    font-family: 'BrandonGrotesqueWeb-Black';
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 60px;
    max-height: 200px;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 3px;
    /* position: absolute; */
    /* overflow-y: scroll; */
  }

    .main-menu-desc {
    text-align: center !important;
    padding-top: 20px;
    margin: 0 auto;
    /* font-weight: bold; */
    text-align: center;
    font-family: 'BrandonGrotesqueWeb-Black';
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 60px;
    max-height: 200px;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 5px;
    /* position: absolute; */
    /* overflow-y: scroll; */
  }

  h8 {
    font-size: 32px;
    font-family: 'Princess Sofia', cursive;
  }

  .card-div {
    margin-right: 300000px;
    position: relative;
    z-index: -100000;
    left: 20;
  }

  a{
    text-decoration: none;
  }

  .angelos-header {
    padding-top: 13px;
    margin: auto;
    display: block;
    text-align: center;
    margin-bottom: 0px;

    /* padding-bottom: 40px; */
    /* width: 50%; */
  }

  .meat-lovers-mobile {
    display: none;
  }

  .sidenav-icons2 {
    width: 1%;
    cursor: pointer;
    padding-left: 13px;
  }

  .icons-div {
    background-color: darkred;
    height: 88%;
  }

  .sidenav-icons2-div {
    /* border-top: 1px solid white; */
    bottom: 0;
    /* opacity: 1; */
    /* background-color: whitesmoke; */
  }
  /* @media only screen and (max-width: 100px) {
    .home-class {
      height: 700px;
    }
  } */




@media only screen and (min-width: 990px) {
  .home-class {
    height: 420px !important;
  }

  .home-class {
    height: 480px;
    /* height: 400px; */
    /* width: 95%; */
}}

@media only screen and (min-width: 1200px) {
  .party-room-caption {
    text-align: center;
    position: absolute;
    bottom: 220%;
    /* transform: translate(-50%, -50%); */
    font-size: 50px;
    text-shadow: 2px 2px 8px black;
    color: white;
    padding-top: 15px;
    border: 1px solid white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    background-color: white;
    opacity: 0.9;
  
    /* padding-bottom: 600px;  */
    /* position: relative; */
    z-index: 30000; 
  }

  .home-class {
    height: 430px !important;
  }
  }

@media only screen and (min-width: 1600px) {
  .home-class {
    height: 575px !important;
  }
  .party-room-caption {
    text-align: center;
    position: absolute;
    bottom: 350%;
    padding-top: 15px;

  }
}



/* Change styles for span and cancel button on extra small screens */
@media only screen and (max-width: 990px) {
  .carousel-caption-meat-lovers {
    position: relative;
    text-align: center;
    left: 50%; 
    top: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 200%;
    display: none;
   }

   .home-class {
    margin-top: 1px;
    width: 95%;
    z-index: -100000;
    position: fixed;
   }

   #mySidebar {
    display: block;
    overflow-x: hidden;

  }

.aidenav-icons2 {
  width: 4%;
}

.top-fade {
  height: 400px;
  background-color: brown;
  position: fixed;
  z-index: 200000;
}

.container-menu-mobile {
  box-shadow: 5px 120px 20px brown inset;
}

   .reserve-room-title {
    color: white;
    font-size: 30px;
    text-align: center;
    text-shadow: 2px 2px 8px black;
  }

  .pompeii-title {
    font-size: 30px;
  }


.reserve-tablet {
  display: block;
}

   .reserve-hide{
     display: none;
   }

   .carousel-caption-meat-lovers-mobile {
    display: block;
    position: absolute;
    top: 10%;
    margin-bottom: 100%;
    right: 10%;
    text-align: right;
    /* width: 100% !important; */
  }

  .reserve-caption {
    width: 80%;
    font-size: 55px;
    text-shadow: 2px 2px 8px black;
    color: brown;
    font-family: 'Forum', cursive;
    /* font-weight: bold; */
    font-family: 'Tinos', serif;
    font-family: 'Princess Sofia', cursive;
    opacity: 1;
    text-align: right;
  }
  
  .carousel-inner {
    /* bottom: -10%; */
    /* margin-top: 200px; */
    box-shadow: 0 8px 8px 8px darkred inset;
    -webkit-box-shadow: inset 0 8px 6px -6px black;
    -moz-box-shadow: inset 0 8px 6px -6px black;
         box-shadow: inset 0 8px 6px -6px black;
         overflow-y: hidden;

  }
  .carousel-slide {
    box-shadow: 20px 20px 20px brown inset;
    -webkit-box-shadow: inset 0 8px 6px -6px black;
    -moz-box-shadow: inset 0 8px 6px -6px black;
         box-shadow: inset 0 8px 6px -6px black;
  }

  .carousel-item {
    -webkit-box-shadow: 0 8px 6px -6px brown;
    -moz-box-shadow: 0 8px 6px -6px brown;
         box-shadow: 0 8px 6px -6px brown;
  }

  .meat-lovers-home {
    bottom: 0;
    height: 100%;
  }

  .menu-caption {
    text-align: right;
    position: absolute;
    bottom: 580%;
    right: 20%;
    /* transform: translate(-50%, -50%); */
    font-size: 40px;
    text-shadow: 2px 2px 8px black;
    color: white;
    font-family: 'Tinos', serif;

  }

  .menu {
    font-size: 65px;
  }

  .meat-lovers-tablet {
    font-size: 40px;
    text-shadow: 2px 2px 8px black;
    color: white;
    font-family: 'Forum', cursive;
    font-family: 'Tinos', serif;
    font-family: 'BrandonGrotesqueWeb-Black';
    font-family: 'Permanent Marker', cursive;
    font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif;
    padding-bottom: 100px !important;
    line-height: 0.8;
    letter-spacing: -2.7px;
    margin-bottom: 40px;
    margin-top: 100px !important;
    /* width: 80%; */
    /* width: 1000px !important; */
    /* font-family: 'Princess Sofia', cursive; */
  }



  .hamburger-menu {
    display: block;
    z-index: 1000;
  }

  .grapes {
    display: none;
  }

  .angelos-header {
    font-size: 48px;
    margin-bottom: -16px;
  }

  .grapes-left {

    height: 400px;
    opacity: 0.3;
  }

.meat-lovers-home-full {
    display: none;
}
.meat-lovers-home-vertical {
  display: inline-block;
  /* width: 100%; */
  text-align: center;
  margin-top: auto 0;
  /* width: 560px; */
  min-width: 80%;
  height: 120%;
  width: 124%;
  /* max-height: 200%; */
}


.meat-lovers-caption {
  position: absolute;
  bottom: 660 !important;
  left: 0;
  padding-top: 0px;
  /* transform: translate(-50%, -50%); */
  font-size: 60px;
  text-shadow: 2px 2px 8px black;
  color: white;
  font-family: 'Tinos', serif;
  border: 0px solid white;
  box-shadow: 0 0px 0px rgba(0,0,0,0.19), 0 0px 0px rgba(0,0,0,0.23);
  padding-left: 15%;
  padding-right: 0px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 80px;
}

h1 {
  display: none;
}

.credit-card-div {
  display: none;
}



.dining-caption {
  text-align: left;
  position: absolute;
  bottom: 880%;
  left: 0%;
  /* transform: translate(-50%, -50%); */
  font-size: 40px;
  text-shadow: 2px 2px 8px brown;
  background-color: transparent;
  opacity: 1;
  color: white;
  padding-top: 15px;
  padding-left: 0px;
  padding-right: 0px;
  border: 1px solid white;
  /* padding-bottom: 600px;  */
  /* position: relative;
  z-index: 3; */ 
}
.dining {
  font-size: 65px;
}

/* .btn {
  width: 80%;
  margin-top: -120px;
} */

.dining-blurb {
  display: none;
}

.sidenav-title {
  font-size: 35px;
  color: white;
  display: block;
  overflow-x: hidden;
  transition: 0.5s;
  text-decoration: none;
}

  .sidebar {
    overflow-x: hidden;
    display: block;
    height: 0%; /* 100% Full-height */
    width: 100%;
    z-index: 200000000;
  }

.sidenav-line {
  color: white;
  font-size: 20px;
}

.sidenav-pizza {
  font-size: 28px;
  color: white;
  font-family: 'Princess Sofia', cursive;
}

.sidenav-menu-options {
  padding-top: 1%;
  text-align: left;
  margin: auto;
  width: 100%;
  position: absolute;
  display: block;
  text-align: center;
}
.card {
  width: 100%;
}
  
.card-text {
  font-size: 18px;
}

h8 {
  font-size: 25px;
}

/* .home-class {
  height: 460px;
} */

h5{
  font-size: 58px;
}

h8 {
  font-size: 33px;
}

.page-sub-header {
  padding-top: 5px;
}

.meat-lovers-mobile {
  display: none;
}


.container {
  border-bottom: 1px solid brown;
  /* text-align: center; */
  width: 100%;
  margin: auto;
  display: block;
}


.reserve-slider {
  left: 15px;
  margin-left: 3%;
}

.party-room-caption {
  text-align: center;
  position: absolute;
  bottom: 700%;
  /* transform: translate(-50%, -50%); */
  font-size: 50px;
  text-shadow: 2px 2px 8px black;
  color: white;
  padding-top: 15px;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);

  /* padding-bottom: 600px;  */
  /* position: relative;
  z-index: 3; */ 
}

.reserve-slider {
  top: 0;
  width: 82%;
  left: 15px;
  margin-left: 3%;
}

.curtain {
  height: 100%;
}

button {
  background-color: darkred; /* Green */
  border: 0px solid white;
  color: white;
  padding: 17px 40px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.8s;
  font-family: 'BrandonGrotesqueWeb-Black';
  /* box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19); */
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

}

button:hover {
  /* background-color: white; */
  /* color: brown; */
  box-shadow: 0 12px 16px 0 brown,0 17px 50px 0 brown;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 1s;
}



}
@media only screen and (max-width: 550px) {

  .navbar-custom {
    position: absolute;
    width: 100%;
    background-color: white;
    color: rgb(165,42,42);
    text-align: center;
    height: 0px;
    z-index: 1000000;
  }

  .home-click {
    color: darkred;
    letter-spacing: 2px;
    font-size: 12px;
    font-family: 'BrandonGrotesqueWeb-Black';
    cursor: pointer;
  }

  .sub-menu-desc {
    text-align: center !important;
    padding-top: 20px;
    margin: 0 auto;
    /* font-weight: bold; */
    text-align: center;
    font-family: 'BrandonGrotesqueWeb-Black';
    cursor: pointer;
    font-size: 20px;
    margin-bottom: 60px;
    max-height: 200px;
    padding-left: 0px;
    padding-right: 0px;
    letter-spacing: 3px;
    /* position: absolute; */
    /* overflow-y: scroll; */
  }

  .td-price-price {
    padding-left: 20px;
    padding-right: 20px;
    text-align: center;
    font-size: 30px;
    margin: 0 auto;
    color: darkred;
    /* font-weight: bold; */
    text-align: center;
    font-family: 'BrandonGrotesqueWeb-Black';
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 60px;
    padding-bottom: 180px !important;
    max-height: 200px;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 2px;
    padding-top: 40px !important;
    /* position: absolute; */
    /* overflow-y: scroll; */
  }

  .card-sub-menu {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
    /* box-shadow: darkred 0px 25px 20px -20px;  */
    width: 360px;
    /* width: 700px; */
    margin: 0 auto;
    transition: 0.3s;
    min-height: 200px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 30px;
    padding-top: 20px;
    padding-bottom: 24px;
    position: relative;
    z-index: 1000000000;
    border-bottom: 1px solid darkred;

  }

  .main-menu-header {
    /* margin-top: 40px;  */
    /* padding-top: 20px; */
    font-weight: normal;
    color: white !important;
    letter-spacing: 5px !important;
    font-family: 'BrandonGrotesqueWeb-Black' !important;
    font-family: 'Caveat', cursive !important;
    font-weight: bolder;
    background-color: darkred;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    z-index: 10000000000000;
  }

  .card-main-menu {
    /* Add shadows to create the "card" effect */
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    /* box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px; */
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
    /* box-shadow: darkred 0px 25px 20px -20px;  */
    margin: 0 auto;
    width: 370px;
    /* width: 700px; */
    transition: 0.3s;
    min-height: 20px;
    /* padding-left: 10px;
    padding-right: 10px; */
    margin-bottom: 8px;
    padding-top: 30px;
    padding-bottom: 13px;
    margin: 0 auto;
    position: relative;
    z-index: 1000000000;
    padding-left: 0px;
    padding-right: 0px;
    /* border: 1px solid darkred; */
    border-bottom: 1px solid darkred;
    transform: translate(50%, 50);

  }

  .main-food-menu {
    position: absolute;
    /* left: 50%; */
    left: 0;
    /* width: 100%; */
    /* transform: translate(-50%, 0); */
   margin: 0 auto;
   /* overflow-x: hidden; */
    height: 490px;
    }

  .main-table-divide {
    color: white;
    height: 12px;
    font-family: 'Caveat', cursive !important;
    border-top: 1px solid brown;
    width: 40%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: -5px;
  }

  .table-divide {
    color: white;
    height: 12px;
    font-family: 'Caveat', cursive !important;
    border-top: 1px solid brown;
    width: 80px;
    margin: 0 auto;
    margin-bottom: 5px;
  }

  .main-selection-menu {
    position: absolute;
    left: 50%;
    padding-top: -10px !important;
    transform: translate(-50%, 0);
   margin: 0 auto;
   width: 100%;
   overflow-x: hidden;
   height: 100%;
  }


  .meat-lovers-caption {
    position: absolute;
    bottom: 100%;
    left: 0%;
    padding-top: 130px;
    /* transform: translate(-50%, -50%); */
    font-size: 60px;
    text-shadow: 2px 2px 8px black;
    color: white;
    font-family: 'Tinos', serif;
    margin: 0 auto;
  }

  .td-price-item {
    padding-top: 100px !important;
    text-align: center !important;
    /* word-wrap: break-word; */
    font-size: 22px;
    border-bottom: 0px solid brown !important;
  }


  .td-price-item.inside-row {
    padding-top: 40px !important;
    text-align: center !important;
    margin: 0 auto;
    /* word-wrap: break-word; */
    font-size: 22px;
    border-bottom: 0px solid brown !important;
  }

  .menu-choice-tr {
    text-align: center;
    margin: 0 auto;
  }

  .td-price-desc {
    /* margin-left: 0px !important; 
    margin-right: 0px !important; */
    /* transform: translate(-50%, -50%); */
    text-align: center; 
    /* word-wrap: break-word;  */
    font-family: 'Caveat', cursive !important;
    font-size: 22px;
    font-weight: 475;
  }


  th {
    font-size: 14px;
  }


  .menu-header {
    margin-top: 0px; 
    padding-top: 20px;
    letter-spacing: 10px !important;
  }

  .menu-header {
    /* margin-top: 40px;  */
    /* padding-top: 20px; */
    font-weight: normal;
    color: white !important;
    letter-spacing: 5px !important;
    font-family: 'BrandonGrotesqueWeb-Black' !important;
    font-family: 'Caveat', cursive !important;
    font-weight: bolder;
    background-color: darkred;
    width: 100%;
    margin: 0 auto;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
    margin-bottom: 20px;
  }



  

  tr {
    width: 100px;
    cursor: pointer;
    text-align: center;

  }

  table {
    margin-top: 5px;
    text-align: center;
    overflow-x: hidden;
  }

  .menu-choice-table {
    /* margin-top: 160px; */
    width: 400px !important;
    z-index: 30000000000000000;
    position: absolute;
    table-layout: fixed;
    margin-top: -20px;
    margin: 0 auto;
    /* padding-top: 5px; */
    text-align: center !important;
  }

  .main-food-menu {
    padding-top: 10px;
  }

  .sidebar {
    overflow-x: hidden;
    display: block;
    height: 9%; /* 100% Full-height */
    width: 100%;
    /* z-index: 1000000;
    position: fixed; */
  }

  .background-bottom {
    background-color: brown;
    height: 80px;
  }

  .party-room-caption {
    text-align: center;
    position: absolute;
    bottom: 750%;
    /* transform: translate(-50%, -50%); */
    font-size: 50px;
    text-shadow: 2px 2px 8px black;
    color: white;
    padding-top: 15px;
    border: 1px solid white;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  
    /* padding-bottom: 600px;  */
    /* position: relative;
    z-index: 3; */ 
  }

  table, td, th {  
    /* border: 1px solid brown; */
    text-align: center;
    padding-top: 16px;
    padding-bottom: 14px;
    word-wrap: break-word;
    /* margin-top: -50px; */
  }

  th {
    font-family: 'BrandonGrotesqueWeb-Black' !important;
    color: white !important;
  }

  .reserve-caption {
    width: 80%;
    font-size: 38px;
    text-shadow: 2px 2px 8px black;
    color: white;
    font-family: 'Forum', cursive;
    /* font-weight: bold; */
    font-family: 'Tinos', serif;
    font-family: 'Princess Sofia', cursive;
    opacity: 1;
  }

  li.reserve-bullets {
    color: white;
    list-style-type: square;
    font-size: 13px;
    text-shadow: 2px 2px 4px black;
  
  }
  
  

  .container {
    border-bottom: 1px solid brown;

    display: none;
    margin: auto;
  }

  h5{
    font-size: 45px;
  }

  .page-sub-header {
    padding-top: 10px;
    display: none;
  }
  

.home-class {
  height: 430px;
}
  /* .hamburger-menu {
    padding-right: 7px;
    margin-left: -30px;
    width: 10%;
  } */

  .grapes-left {
    opacity: 0.4;
    display: none;
  }

  .reserve-slider {
    top: 0;
    left: 15px;
    margin-left: 1%;
  }

  .credit-cards {
    display: none;
  }

  .angelos-header {
    font-size: 46px;
    display: none;
  }

  .test-classII {
    width: 80%;
  }

  .mobile-modal {
    display: block;
    width: 100%;
    left: 0%;
    margin-top: -200px;
    background-color: brown;
    box-shadow: 5px 120px 20px 10px brown inset;
    overflow-y: hidden;
    /* overflow-y: scroll; */
  }

  .container-menu-mobile {
    width: 100%;
    height: 100%;
    top: 0;
    background-color: brown;
    -webkit-box-shadow: inset 0 8px 6px -6px brown;
    -moz-box-shadow: inset 0 8px 6px -6px brown;
         box-shadow: inset 0 8px 6px -6px brown;
         overflow-y: hidden;


  }
  .menu-mobile {
    height: 99%;
    border-top: 150px solid brown;
    margin-top: -122px;
    width: 96%;
    /* border: 1px solid brown; */
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    opacity: 1;
    -webkit-box-shadow: 0 8px 6px -6px brown inset;
    -moz-box-shadow: 0 8px 6px -6px brown inset;
         box-shadow: 0 8px 6px -6px brown inset;
         /* filter: blur(0.5px); */
         /* -webkit-filter: blur(0.5x); */
    overflow-y: hidden;
  }

  .button-click {
    position: relative;
  }

  .button-click2 {
    position: relative;
    display: none;
  }

  .button-click7 {
    position: relative;
    display: none;
  }

  .nav-link.account-create {
    line-height: 1px;
  }
  
  .button.button5 {
    background-color: whitesmoke;
    border: 4px solid brown;
    color: brown;
    padding: 40px;
    /* padding: 40px; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    z-index: 100000000;
    /* margin-top: 30px; */
    margin-top: 40px;
    box-shadow: 0 -3px 20px 0 brown, 0 -4px 20px 0 black;
    transition-duration: 0.4s;
    position: fixed;
    cursor: pointer;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .button.button6 {
    background-color: whitesmoke;
    border: 4px solid brown;
    color: brown;
    padding: 10px;
    /* padding-top: -20px;
    padding-bottom: -15px; */
    height: 100px;
    width: 110px;
    /* margin-left: 30%; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    /* font-size: 40px; */
    z-index: 100000000;
    /* margin: 4px 2px; */
    /* transform: translateY(-30%); */

    /* margin: auto 0; */
    margin-top: 17px;
 
    box-shadow: 0 -3px 20px 0 brown, 0 -4px 20px 0 black;
    transition-duration: 0.4s;
    position: fixed;
    /* margin-left: -14%; */
    cursor: pointer;

    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .button.button7 {
    background-color: whitesmoke;
    border: 4px solid brown;
    color: brown;
    padding: 10px;
    /* padding-top: -20px;
    padding-bottom: -15px; */
    height: 100px;
    width: 110px;
    /* margin-left: 30%; */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    /* font-size: 40px; */
    z-index: 100000000;
    /* margin: 4px 2px; */
    /* transform: translateY(-30%); */

    /* margin: auto 0; */
    margin-top: 5px;
 
    box-shadow: 0 -3px 20px 0 brown, 0 -4px 20px 0 black;
    transition-duration: 0.4s;
    position: fixed;
    /* margin-left: -14%; */
    cursor: pointer;

    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .button.button5:hover {
    box-shadow: 0px 0px 0px black;

  }

  .button.button7:hover {
    box-shadow: 0px 0px 0px black;

  }

  .button.button6:hover {
    box-shadow: 0px 0px 0px black;

  }

  .far.fa-angle-up {
    color: brown;
  }

  .button5 {border-radius: 50%;}

  .button6 {border-radius: 50%;}


  .button7 {border-radius: 50%;}

.card-text {
  font-size: 7px;
}
.dining-blurb {
  display: hidden;
}

h8 {
  font-size: 26px;
}

#row {
  width: 70%;
}

.meat-lovers-mobile {
  display: block;
  width: 350px;
  max-height: 100%;
  margin: auto;
  text-align: center;
  margin-top: auto 0;
  width: 340px;
  min-height: 100%;
  max-height: 100%;
}

.meat-lovers-home-vertical {
  display: block;
}

.meat-lovers-home-full {
  display: none;
}

.balloon {
  width: 63%;
  position: absolute;
  z-index: -100;
  bottom: 0;
  left: 0;
  margin-left: -90px;
  margin-bottom: -35px;
}

.home-class {
  display: none;
}

.sidenav-icons2 {
  width: 42px;
  cursor: pointer;
  padding-left: 15px;
}

.map-img {
  /* transform: translate(-50%, -50%); */
  margin: auto;
  width: 26%;
  left: 40%;
  top: 38%;
  position: fixed;
  z-index: 1000;
}

.down-arrow-div {
  width: 100%;
  cursor: pointer;
}

.down-arrow {
  margin-top: 30px;
  width: 120px;
  cursor: pointer;
}

.down-arrow-contact {
  width: 80px;
  cursor: pointer;
}

.sidenav-icons2-div {
  bottom: 4%;
  padding-top: 720px;
}

.carousel-control-prev {
  position: fixed;
  top: 10%;
  padding-left: -20px;
}

.carousel-control-next {
  position: fixed;
  top: 10%
}


.btn {
  width: 60%;
  margin-top: -580px;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;

}

.icons-line {
  border-bottom: 1px solid white;
  width: 40%;
  margin: auto;
  padding-top: 580px;
  margin-bottom: -300px;
} 

.flag {
  /* top: 41%; */
  top: 36.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  margin: auto;
  z-index: 999;
  position: fixed;
  opacity: 0.8;
  width: 40%;
  /* height: 14%; */
  height: 120px;
}

.mobile-title {
  color: white;
  z-index: 10000;
  vertical-align: center;
  top: 45%;
  font-family: 'Cinzel';
  background-color: transparent;
  font-size: 55px;
  font-weight: bold;
  position: fixed;
  top: 33.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 4px black;
  padding-left: -5px;
  padding-right: -10px;
  border-bottom: 1px solid white;
  width: 70%;
  letter-spacing: 1px;

}

.top-border-red {
  position: fixed;
  width: 100%;
  left: 0;
  height: 0px;
  top: 0;
}

.mobile-title-pizza {
  color: white;
  z-index: 100000;
  vertical-align: center;
  top: 40%;
  font-family: 'Cinzel';
  background-color: transparent;
  font-size: 30px;
  font-weight: bold;
  position: fixed;
  top: 39%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 4px black;
  letter-spacing: 1px;
}

.mobile-title-of {
  color: white;
  z-index: 1001;
  vertical-align: center;
  top: 40%;
  font-family: 'Cinzel';
  font-family: 'Princess Sofia', cursive;
  font-family: 'Marck Script';
  background-color: transparent;
  font-size: 30px;
  font-weight: 999;
  position: fixed;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-shadow: 1px 1px 4px black;
}

.mobile-address {
  color: white;
  z-index: 9999999;
  /* vertical-align: text-top; */
  /* top: 25%; */
  font-family: 'Cinzel';
  /* font-family: 'Permanent Marker', cursive; */
  /* font-family: 'BrandonGrotesqueWeb-Black';  */
  /* background-color: transparent; */
  text-align: left;
  font-size: 15px;
  font-weight: bold;
  position: fixed;
  bottom: 3%;
  line-height: 16px;
  /* display: inline-block; */
  /* right: 4%; */
  /* margin: auto 0; */
  /* transform: translate(-50%, -50%); */
  /* text-shadow: 1px 1px 2px black; */
  /* text-shadow: 1px 1px 2px black; */
  text-shadow: 1px 1px 4px black;

  left: 4%; 
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);  */
}


.mobile-address-right {
  color: white;
  z-index: 9999999;
  /* vertical-align: text-top; */
  /* top: 25%; */
  font-family: 'Cinzel';
  /* font-family: 'Permanent Marker', cursive; */
  /* font-family: 'BrandonGrotesqueWeb-Black';  */
  /* background-color: transparent; */
  text-align: right;
  font-size: 15px;
  font-weight: bolder;
  position: fixed;
  bottom: 3%;
  line-height: 16px;
  text-shadow: 1px 1px 4px black;

  /* display: inline-block; */
  /* right: 4%; */
  /* margin: auto 0; */
  /* transform: translate(-50%, -50%); */
  /* text-shadow: 1px 1px 2px black; */
  /* text-shadow: 1px 1px 2px black; */
  right: 4%; 
  /* -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);  */
}

.contact {
  color: white;
  text-align: center;
}



.mobile-title-card {
  z-index: 999;
  text-align: center;

}

.delivery-dine {
  background-color: darkred;
  width: 100%;
}

.header-buffer {
  width: 4px;
}

.table-buffer {
  width: 0.25px !important;
  background-color: brown;
  border-bottom: 0px solid white;
}

.delivery-text {
  z-index: 999;
  font-size: 15px;
  text-align: center;
  color: white;
  top: 3%;
  font-weight: bold;
  font-family: 'Cinzel';
  position: fixed;
  transform: translate(-50%, -50%);
    text-shadow: 1px 1px 4px black;

}

.contact-sidebar {
height: 5%; /* 100% Full-height */
  width: 100%;; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 100000; /* Stay on top */
  bottom: 0;
  left: 0;
  background-color: darkred; /* Black*/

  padding-top: 0px; /* Place content 60px from the top */
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidebar */
  opacity: 0.9;
  /* box-shadow:    -3px 0 5px 5px black; */
  overflow-x: hidden; /* Disable horizontal scroll */
  overflow-y: hidden;
}

tr {
  width: 200px;
  cursor: pointer;
  text-align: center;
}



.down-arrow {
  margin-top: 20px;
  /* width: 15%; */
  width: 80px;
  cursor: pointer;
  z-index: 100000;
}

.contact-method {
  color: white;
  font-family: 'BrandonGrotesqueWeb-Black';
  font-size: 25px;
  /* text-shadow: 1px 1px 4px black; */
}

.nav-link.account-create {
  line-height: 1px;
}

.fas.fa-angle-down {
  margin-top: 40px;
  color: black;
  cursor: pointer;
  z-index: 100000;
  font-size: 42px;
  /* font-family: Arial, Helvetica, sans-serif; */
  text-shadow: 1px -1px 3px brown;

}

.fas.fa-angle-down.contact {
  margin-top: 15px;
  color: black;
  cursor: pointer;
  z-index: 100000;
  font-size: 42px;
  /* font-family: Arial, Helvetica, sans-serif; */
  text-shadow: 1px -1px 3px brown;
}

.fas.fa-angle-up {
  margin-top: -19px;
  color: black;
  cursor: pointer;
  z-index: 100000;
  font-size: 42px;
  /* font-family: Arial, Helvetica, sans-serif; */
  text-shadow: 1px -1px 3px brown;

}

.social-media-line {
  font-size: 20px !important;
  text-align: center;
  color: white
}
}
