.footer-custom {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  height: 60px;
  z-index: -1;
}

.col-md-7 {
  text-align: left;
  padding-top: 75px;
}

h7 {
  color: black;
}

.button.button9 {
  border-radius: 50%;
  background-color: whitesmoke;
  border: 4px solid brown;
  color: brown;
  padding: 10px;
  /* padding-top: -20px;
  padding-bottom: -15px; */
  height: 100px;
  width: 110px;
  /* margin-left: 30%; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 40px; */
  z-index: 100000000;
  /* margin: 4px 2px; */
  /* transform: translateY(-30%); */

  /* margin: auto 0; */
  margin-top: 5px;

  box-shadow: 0 -3px 20px 0 brown, 0 -4px 20px 0 black;
  transition-duration: 0.4s;
  position: fixed;
  /* margin-left: -14%; */
  cursor: pointer;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-bottom: 60px;
}

.button.button10 {
  border-radius: 50%;
  background-color: whitesmoke;
  border: 4px solid brown;
  color: brown;
  padding: 10px;
  /* padding-top: -20px;
  padding-bottom: -15px; */
  height: 100px;
  width: 110px;
  /* margin-left: 30%; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 40px; */
  z-index: 10;
  /* margin: 4px 2px; */
  /* transform: translateY(-30%); */

  /* margin: auto 0; */
  margin-top: 0px;
  padding-top: 5px;
  box-shadow: 0 -3px 20px 0 brown, 0 -4px 20px 0 black;
  transition-duration: 0.4s;
  position: fixed;
  /* margin-left: -14%; */
  cursor: pointer;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin-bottom: 60px;
}

.button.button10:hover {
  box-shadow: 0px 0px 0px black;

}


.fas.fa-angle-down {
  margin-top: 10px;
  color: black;
  cursor: pointer;
  z-index: 100000;
  font-size: 42px;
  /* font-family: Arial, Helvetica, sans-serif; */
  text-shadow: 1px -1px 3px brown;
}

.fas.fa-angle-down.ten {
  margin-top: 30px !important;
  color: black;
  cursor: pointer;
  z-index: 100000;
  font-size: 42px;
  /* font-family: Arial, Helvetica, sans-serif; */
  text-shadow: 1px -1px 3px brown;
}

.button-click9 {
  position: relative;
}

.col-md-5 {
  padding-left: 30px;
}

.week-days {
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 5px;
  font-family: 'Caveat', cursive !important;
  color: black;
  text-shadow: 1px -1px 3px black;
  text-align: left;
  padding-left: 5%;
  margin-bottom: -10px;
  width: 300px;
  /* line-height: -50px !important;
  margin-bottom: -5px; */

  /* text-shadow: 1px 1px 4px black; */
}

.footer-border-and-chef {
  position: fixed;
  z-index: -10000;
}

.chef-image {
  margin-top: -33%;
  margin-left: 0%;
  margin-bottom: 0%;
  display: block;
  position: fixed;
  bottom: 0;
  height: auto;
  width: 35%;
  z-index: -1000 !important;
  opacity: 1;
}

h4 {
  margin-top: 18px;
  margin-right: 10px;
  font-family: 'BrandonGrotesqueWeb-Black';
  font-size: 17px;
  text-align: right;
}

h9 {
  font-family: 'Princess Sofia', cursive;
  font-size: 24px;
  color: darkred;
  font-weight: 570;
  text-shadow: 1px 1px 4px black;
  text-align: center;
  padding-top: -30px;
  text-shadow: 0px 8px 10px rgba(81,67,21,0.8);

}

.address-line {
  margin-top: -120px;
}

.footer-line {
  margin-bottom: -18px;
  width: 35%;
  border-top: 1px solid brown;
  margin: auto;
  padding-bottom: -18px;
}

.red-bottom-border {
  position: fixed;
  right: 0;
  width: 100%;
  height: 42px;
  bottom: 0;
}

.social-media-icons {
  width: 31px;
  cursor: pointer;
  bottom: 18;
}

#instagram-icon {
  width: 43px;
}

#phone-icon {
  width: 27px;
}

.social-medias {
  margin: auto 0;
  padding-bottom: 10px;
  padding-top: 0px;
}

.credit-cards {
  width: 10%;
  padding-bottom: 5px;
}

.sidenav-contact {
  height: 0;
  width: 60%;
  left: 20%;
  position: fixed;
  z-index: 100000000000000000;
  bottom: 0;
  background-color: white;
  border-top: 1px solid brown;
  overflow-x: hidden;
  transition: 0.5s;
  margin: auto;
  margin-right: auto;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  overflow: hidden;
}

.sidenav-map {
  height: 0;
  width: 60%;
  left: 20%;
  position: fixed;
  z-index: 22222222222;
  bottom: 0;
  background-color: darkred;
  border-top: 1px solid brown;
  overflow-x: hidden;
  transition: 0.5s;
  margin: auto;
  margin-right: auto;
  overflow-y: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;

}

.sidenav-map .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  margin-left: 50px;
  color: white;
}

.sidenav-contact .closebtn {
  position: absolute;
  bottom: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 0px;
  color: brown;
}

.sidenav-map .closebtn {
  position: absolute;
  bottom: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  color: white;
}

h2 {
  text-shadow: 2px 2px 8px black;
  font-size: 20px;
}

.phone-number {
  color: darkred;
  font-size: 40px;
  font-family: 'Permanent Marker', cursive;
  font-family: 'BrandonGrotesqueWeb-Black';
  text-shadow: .5x .5px 2px black;
  padding-bottom: -23px;
  letter-spacing: 3px;
  font-family: 'Caveat', cursive !important;
  font-family: 'BrandonGrotesqueWeb-Black' !important;
  line-height: -5px !important;

}

.phone-icon {
  width: 3%;
}

.phone-number-div {
  padding-top: 16px;
  margin-bottom: -40px;
}

.hours {
  padding-top: 20px;
  padding-bottom: 20px;
  height: 400px;
}

.tag-line {
 text-shadow: 1px 1px 4px black;
 font-size: 23px;
 background-color: darkred;
 color: white;
 font-family: 'Permanent Marker', cursive;
 font-family: 'BrandonGrotesqueWeb-Black';
  font-family: 'Caveat', cursive !important;
 letter-spacing: 5px;
 /* font-family: 'Caveat', cursive !important; */
 font-weight: bold;
 box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
 margin-bottom: 20px;

 /* font-family: 'Princess Sofia', cursive;
 font-family: 'Arial Black', 'Arial Bold', Gadget, sans-serif; */


}

.map-address {
  font-family: 'Caveat', cursive !important;
  font-size: 17px;
  text-shadow: 1px 1px 4px black;
}

.tag-line-div {
  background-color: darkred;
  border-bottom: 1px solid darkred;
  padding-top: 10px;
  padding-bottom: -60px;
  position: relative;
  z-index: 1000000000;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
}

.map-line-div {
  background-color: darkred;
  border-bottom: 1px solid brown;
  /* padding-top: 3px;
  padding-bottom: 3px; */
  color: white;
  height: 4px;
  z-index: 2000000000;
}

.button.button9:hover {
  box-shadow: 0px 0px 0px black;

}

.phone-number-hours {
  color: black;
  font-size: 14px;
  text-align: left;
  line-height: -10px;
  margin-left: 60px;
  /* font-weight: bold; */
}

.phone-number-hours {
  color: black;
  font-size: 15px;
  text-align: left;
  /* margin-left: -40px;
  line-height: -10px; */
}

.address {
  cursor: pointer;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.pin {
  width: 20%;
}

h9{
  font-size: 28px;
}

@media only screen and (max-width: 990px) {

  h9 {
    font-size: 22px;
  }

  .address-footer {
    padding-top: -10px;
    text-align: right;
    padding-right: 30px;
    margin-top: -45px;
  }

  .credit-cards {
    width: 17%;
    margin-bottom: -50px;
  }

  .red-bottom-border {
    height: 15px;
  }

  .sidenav-map {
    height: 0;
    width: 90%;
    left: 5%;
    position: fixed;
    z-index:  20000000000;
    bottom: 0;
    background-color: bisque;
    border-top: 1px solid brown;
    overflow-x: hidden;
    transition: 0.5s;
    margin: auto;
    margin-right: auto;
    overflow: hidden;
  }

  .sidenav-contact {
    height: 0;
    width: 94%;
    left: 3%;
    position: fixed;
    z-index: 100000;
    bottom: 0;
    background-color: bisque;
    border-top: 1px solid brown;
    overflow-x: hidden;
    transition: 0.5s;
    margin: auto;
    margin-right: auto;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    overflow: hidden;
  }


.chef-image {
  margin-top: -33%;
  margin-left: 0%;
  margin-bottom: 0%;
  display: block;
  position: fixed;
  bottom: 0;
  height: auto;
  width: 50%;
  z-index: -1000 !important;
  opacity: 0.7;
}
}
@media only screen and (max-width: 950px) {
  .tag-line {
    font-size: 11px;
  }

  .social-medias {
    display: block;
  }

  .address-mobile {
    display: none;
  }
}
  @media only screen and (max-width: 500px) {

    .social-medias {
      display: none;
    }

  .address {
    display: none;
  }

  .address-mobile {
    font-family: 'Fredericka the Great', cursive;
    display: none;
    text-align: center;
    margin-top: -10px;
    cursor: pointer;
    font-size: 32px;
    font-weight: bold;
    }

    h2 {
      font-family: 'Fredericka the Great', cursive; 
      font-size: 22px;
      color: brown;
      font-weight: bold;
    }

    .chef-image {
      margin-top: -30%;
      margin-left: 0%;
      margin-bottom: 0%;
      display: block;
      position: fixed;
      bottom: 0;
      height: auto;
      width: 30%;
      z-index: -1000 !important;
      opacity: 0.9;
    }

    .footer-custom {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      color: white;
      height: 60px;
      z-index: -1000000000000000;
      display: none;
    }

    .footer-border-and-chef {
      display: none;
    }
}