.reset-password-modal {
    top: 140px;
    margin-top: 30px;
    height: 480px;
    width: 80%;
    margin: auto;
    opacity: 0.9;
    position: relative;
    z-index: initial;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
    overflow-y: auto;
    border: 1px solid brown;
    box-sizing: border-box;
  }